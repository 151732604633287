<template>
  <div>
    <div class="flex justify-content items-center border-b-2">
      <h2 class="card-title pb-0 mb-0">Successful Orders</h2>
      <div class="text-center -mb-1 ml-5" v-if="pagination.total > 0">
        <BaseButton
          id="download-button"
          class="my-4"
          variant="secondary-outline"
          :loading="buttonLoading"
          icon="arrow-down-to-line"
          @click.native="downloadReport"
        ></BaseButton>
        <b-tooltip target="download-button" triggers="hover focus" placement="right"> Download CSV </b-tooltip>
      </div>
    </div>
    <div v-if="!loading && errorMessage">
      <Alert variant="red" icon="exclamation">Error loading failed orders report: {{ errorMessage }}</Alert>
    </div>
    <GenericTable
      v-if="!errorMessage"
      :columns="columns"
      :data="data"
      :loading="loading"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
      :pagination="pagination"
      class="mt-8"
    >
      <template #createdAt="{ row }">
        <span
          v-b-tooltip.hover
          :title="
            formatDateTime(parseISO(row.createdAt), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          "
          class="dashline whitespace-nowrap"
        >
          {{ formatDateTime(parseISO(row.createdAt)) }}
        </span>
      </template>
      <template #orderNumber="{ row }">
        <p>{{ row.orderNumber }}</p>
      </template>
      <template #orderType="{ row }">
        <p class="whitespace-nowrap">{{ row.orderType }}</p>
      </template>
      <template #paymentType="{ row }">
        <p class="whitespace-nowrap">{{ row.paymentType }}</p>
      </template>
      <template #orderSource="{ row }">
        <p>{{ row.orderSource }}</p>
      </template>
      <template #name="{ row }">
        <p class="whitespace-nowrap">{{ row.name }}</p>
      </template>
      <template #secondaryName="{ row }">
        <p>{{ row.secondaryName }}</p>
      </template>
      <template #phone="{ row }">
        <p>{{ row.phone }}</p>
      </template>
      <template #email="{ row }">
        <p>{{ row.email }}</p>
      </template>
      <template #address="{ row }">
        <p>{{ row.address }}</p>
      </template>
      <template #city="{ row }">
        <p>{{ row.city }}</p>
      </template>
      <template #postalZip="{ row }">
        <p>{{ row.postalZip }}</p>
      </template>
      <template #supporting="{ row }">
        <p>{{ row.supporting }}</p>
      </template>
      <template #ticketSeries="{ row }">
        <p>{{ row.ticketSeries }}</p>
      </template>
      <template #totalTickets="{ row }">
        <p>{{ row.totalTickets }}</p>
      </template>
      <template #orderAmount="{ row }">
        {{ formatCurrency(row.orderAmount) }}
      </template>
      <template #merchantFees="{ row }">
        <p>{{ formatCurrency(row.merchantFees) }}</p>
      </template>
      <template #merchant="{ row }">
        <p>{{ row.merchant }}</p>
      </template>
      <template #merchantReferenceId="{ row }">
        <p>{{ row.merchantReferenceId }}</p>
      </template>
      <template #deviceName="{ row }">
        <p>{{ row.deviceName }}</p>
      </template>
      <template #deviceSerialNumber="{ row }">
        <p>{{ row.deviceSerialNumber }}</p>
      </template>
      <template #orderComment="{ row }">
        <p>{{ row.orderComment }}</p>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import Alert from '@/components/ui/Alert.vue';
import { format, parseISO } from 'date-fns';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import { downloadFormattedCSV } from '@/lib/download-file';

import ReportServiceV2 from '@/lib/report-service-v2.js';

export default {
  components: {
    GenericTable,
    Alert,
    BaseButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
      columns: [
        { name: 'createdAt', label: 'Created At', sortable: true },
        { name: 'orderNumber', label: 'Order #', sortable: true },
        { name: 'orderType', label: 'Type', sortable: true },
        { name: 'paymentType', label: 'Payment Type', sortable: true },
        { name: 'orderSource', label: 'Source', sortable: true },
        { name: 'name', label: 'Name', sortable: true },
        { name: 'secondaryName', label: 'Secondary Name', sortable: true },
        { name: 'phone', label: 'Phone', sortable: true },
        { name: 'email', label: 'Email', sortable: true },
        { name: 'address', label: 'Address', sortable: true },
        { name: 'city', label: 'City', sortable: true },
        { name: 'postalZip', label: 'Postal/Zip', sortable: true },
        { name: 'supporting', label: 'Supporting', sortable: true },
        { name: 'ticketSeries', label: 'Ticket Series Purchased', sortable: true },
        { name: 'totalTickets', label: 'Total Tickets Purchased', sortable: true },
        { name: 'orderAmount', label: 'Amount', isCurrency: true, sortable: true },
        { name: 'merchantFees', label: 'Merchant Fee (CA$)', isCurrency: true, sortable: true },
        { name: 'merchant', label: 'Merchant', sortable: true },
        { name: 'merchantReferenceId', label: 'Merchant Reference ID', sortable: true },
        { name: 'deviceName', label: 'Device', sortable: true },
        { name: 'deviceSerialNumber', label: 'Device Serial Number', sortable: true },
        { name: 'deviceSellerName', label: 'Device Seller Name', sortable: true },
        { name: 'orderComment', label: 'Order Comment', sortable: true }
      ],
      data: [],
      modalConfig: {
        showCloseModalButton: false
      },
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0,
        sortBy: 'createdAt',
        sortDir: 'desc'
      },
      buttonLoading: false
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), 'MMMM do, yyyy');
    },
    async loadData() {
      try {
        const params = {
          eventId: this.eventId,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortBy: this.pagination.sortBy,
          sortDir: this.pagination.sortDir.toLocaleUpperCase()
        };

        const data = await ReportServiceV2.retrieveSuccessfulOrdersReport(params);

        this.data = data.data;

        this.pagination.total = data.total;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.loading = false;
    },
    async downloadReport() {
      this.buttonLoading = true;
      try {
        this.csvData = await ReportServiceV2.retrieveSuccessfulOrdersReport({
          contentType: 'text/csv',
          eventId: this.eventId,
          pageSize: this.pagination.total
        });
        downloadFormattedCSV(`successful_orders_report_${Date.now()}.csv`, this.csvData);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    },
    sort(sortBy, sortDir) {
      this.$emit('sorting', true);
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      this.loadData();
    },
    updateCurrentPage(page) {
      this.pagination.page = page;
      this.loadData();
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
