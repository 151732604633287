<template>
  <div class="py-4 grand-prize">
    <div v-if="winnersLoading" class="d-flex justify-content-center mb-3">
      <b-spinner class="ml-auto" variant="success" label="Loading..."></b-spinner>
    </div>

    <div v-else class="mt-4 grand-prize__container">
      <Alert v-if="grandPrizeError" icon="exclamation" variant="red" class="w-6/12 mb-7 mt-5">
        {{ grandPrizeError }}
      </Alert>

      <h5 class="mb-5 text-2xl">Grand Prize</h5>

      <div v-if="isRbAdmin || !showWinners || showFields" class="bg-white border-2 px-4 py-5 sm:p-6 rounded-lg">
        <div class="flex justify-between items-center">
          <div class="flex w-full">
            <!-- Prize Title -->
            <div class="flex">
              <div
                class="flex xs:min-w-min xl:min-w-max items-center grand-prize__inputs"
                v-if="isSuperAdmin || (!showWinners && !displayPickWinnerManualButton && !displayPickWinnerRngButton)"
              >
                <font-awesome-icon class="h-6 w-6 fa-lg text-green-600 mr-4" :icon="['far', 'gift']" />
                <div class="mr-2">
                  <input
                    id="title"
                    name="title"
                    class="text-lg py-2 bg-gray-200"
                    type="text"
                    v-model="title"
                    autocomplete="off"
                    :disabled="disabled"
                  />
                </div>
              </div>
              <div class="flex min-width" v-else>
                <font-awesome-icon class="h-6 w-6 fa-lg text-green-600" :icon="['far', 'gift']" />
                <p class="ml-2 font-bold text-lg">{{ title }}</p>
              </div>
            </div>

            <!-- Prize Description -->
            <div class="w-full ml-2">
              <div
                class="xs:w-full md:max-w-3/4 grand-prize__inputs"
                v-if="isSuperAdmin || (!showWinners && !displayPickWinnerManualButton && !displayPickWinnerRngButton)"
              >
                <input
                  id="description"
                  name="description"
                  class="text-lg py-2 bg-gray-200"
                  type="text"
                  v-model="description"
                  :disabled="disabled"
                  autocomplete="off"
                />
              </div>
              <p class="text-gray-900 text-lg" v-else>{{ description }}</p>
            </div>
          </div>

          <!-- Edit Button -->
          <div class="flex" v-if="isSuperAdmin || (!displayPickWinnerRngButton && !displayPickWinnerManualButton)">
            <div v-if="isRbAdmin">
              <font-awesome-icon
                :class="isSuperAdmin ? 'pr-4' : 'pr-0'"
                class="text-xl fa-2x cursor-pointer hover:opacity-80 ml-2"
                :icon="['far', 'pen-to-square']"
                id="editDeviceButton"
                @click="edit"
                v-if="disabled"
              />
              <Button
                v-else
                variant="green"
                size="small"
                :loading="loading"
                @onClick="edit"
                class="align-self-center ml-2 text-white hover:text-green-600"
              >
                <font-awesome-icon
                  class="text-xl h-5 cursor-pointer"
                  :icon="['far', 'floppy-disk']"
                  id="editDeviceButton"
                />
              </Button>
            </div>
          </div>

          <!-- Pick Winner Buttons -->
          <div>
            <PickWinnerRng
              v-if="displayPickWinnerRngButton"
              :disabled="unpickedPrizeWinners()"
              :event="event"
              @winnerPicked="winnerRefresh"
            />
            <PickWinnerManual
              v-if="displayPickWinnerManualButton"
              :disabled="unpickedPrizeWinners()"
              :event="event"
              :eventV1="eventV1"
              @winnerPicked="winnerRefresh"
            />

            <Button v-if="editGrandPrize" variant="green" size="small" :loading="loading" @onClick="edit" class="mb-2">
              <span v-if="disabled">Edit Prize Labels</span>
              <span v-else>Save Prize Labels</span>
            </Button>
          </div>
        </div>
      </div>

      <div v-if="showWinners" class="grand-prize__winner" :class="{ 'mt-8': !showWinners && showFields }">
        <GrandPrizeWinner :event="event" :winners="winners" :cancelled="cancelled" @complete="complete" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/LoadingButton';
import Alert from '@/components/ui/Alert';
import GrandPrizeWinner from '@/components/prizes/GrandPrizeWinner';
import PickWinnerRng from '@/components/PickWinnerRng';
import PickWinnerManual from '@/components/PickWinnerManual';
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import WinnerService from '@/lib/winner-service-v2';
import EventService from '@/lib/event-service';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Button,
    Alert,
    GrandPrizeWinner,
    PickWinnerRng,
    PickWinnerManual
  },
  data() {
    return {
      event: null,
      eventV1: null,
      title: null,
      description: null,
      disabled: true,
      loading: false,
      winnersLoading: false,
      winners: [],
      cancelled: [],
      grandPrizeError: null,
      showWinners: false,
      showFields: false,
      isRbAdmin: false,
      isSuperAdmin: false
    };
  },
  computed: {
    displayPickWinnerRngButton() {
      return (
        this.event.drawType === 'rng' &&
        (this.event.status === 'ended' || (this.event.status === 'closed' && this.isSuperAdmin)) &&
        this.winners.length === 0
      );
    },
    displayPickWinnerManualButton() {
      return (
        this.event.drawType === 'barrel' &&
        (this.event.status === 'ended' || (this.event.status === 'closed' && this.isSuperAdmin)) &&
        !this.eventV1.winnerTicketId
      );
    },
    editGrandPrize() {
      if (this.isRbAdmin) {
        return false;
      }

      return (
        !this.displayPickWinnerRngButton &&
        !this.displayPickWinnerManualButton &&
        (this.event.category === 'Raffle' || this.event.drawType === 'barrel')
      );
    },
    grandPrizeDescription() {
      if (this.event.settings.grandPrizeDescription) {
        return this.event.settings.grandPrizeDescription;
      } else if (this.event.category === '50/50') {
        return '50% of the total Jackpot';
      } else {
        return '';
      }
    }
  },
  watch: {
    winners() {
      this.showWinners = this.winners.length !== 0;
    }
  },
  async created() {
    this.event = this.$store.state.event;
    const sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
    this.isSuperAdmin = await unleashFeatureEnabled(UnleashKeys.SupportSuperAdmin);
  },
  async beforeMount() {
    if (this.event.drawType === 'barrel') {
      await this.retreiveV1EventAndWinner();
    } else {
      await this.retrieveWinners();
    }
    this.description = this.grandPrizeDescription;
    this.title = this.event.settings.grandPrizeTitle || 'Grand Prize';
  },
  methods: {
    async edit() {
      this.showFields = true;

      if (this.disabled === false) {
        this.loading = true;

        // Unfortunately we must pass all the settings through or risk some of them being updated to null
        const params = {
          eventId: this.event.id,
          event: {
            settings: {
              geoLocation: this.event.settings.geoLocation,
              hidePot: this.event.settings.hidePot,
              hideEntireGroup: this.event.settings.hideEntireGroup,
              hideSocialLinks: this.event.settings.hideSocialLinks,
              hidePrizeList: this.event.settings.hidePrizeList,
              hideWinnersList: this.event.settings.hideWinnersList,
              pixelCode: this.event.settings.pixelCode,
              gtmCode: this.event.settings.gtmCode,
              customJackpotEnabled: this.event.settings.customJackpotEnabled,
              removeCashButton: this.event.settings.removeCashButton,
              jackpotStatement: this.event.settings.jackpotStatement,
              jackpotSubTitle: this.event.settings.jackpotSubTitle,
              whiteLabel: {
                enabled: this.event.settings.whiteLabel.enabled,
                buttonColor: this.event.settings.whiteLabel.buttonColor,
                checkoutBannerUrl: this.event.settings.whiteLabel.checkoutBannerUrl
              },
              grandPrizeTitle: this.title,
              grandPrizeDescription: this.description
            }
          }
        };

        try {
          await this.$store.dispatch('updateGrandPrize', params);
          this.event = this.$store.state.event;
          this.loading = false;
          this.showFields = false;
        } catch (error) {
          this.grandPrizeError = error;
          this.loading = false;
        }
      }

      this.disabled = !this.disabled;
    },
    unpickedPrizeWinners() {
      // There should be no outsanding additional prize draws
      const prizes = this.$store.getters.getPrizeList;
      if (prizes.winners) {
        const confirmed = prizes.winners.filter((prize) => {
          return prize.status === 'confirmed';
        });

        if (confirmed.length !== prizes.length) {
          return true;
        }
      }

      return false;
    },
    async retrieveWinners() {
      this.winnersLoading = true;

      const winnersResponse = await WinnerService.listWinners(this.event.id, {
        status: 'not_cancelled',
        category: 'jackpot'
      });

      if (winnersResponse.data) {
        this.winners = winnersResponse.data;
      }

      // Also retrieve cancelled winners.
      const cancelledResponse = await WinnerService.listWinners(this.event.id, {
        status: 'cancelled',
        category: 'jackpot'
      });

      this.cancelled = cancelledResponse.data;

      this.winnersLoading = false;
    },
    async retreiveV1EventAndWinner() {
      this.winnersLoading = true;

      const response = await EventService.readEvent(this.event.id);
      this.eventV1 = response.data;
      const winningTicket = response.data.winningTicket;

      if (winningTicket) {
        // There is no winner confirmation step in V1. Manully adding this here so the confirmation buttons don't appear in the winner display.
        winningTicket.status = 'confirmed';

        // V1 doesn't return fullTicketNumber
        winningTicket.ticket = {
          fullTicketNumber: `${winningTicket.series}-${winningTicket.ticketNumber}`
        };

        this.winners = [winningTicket];
      } else {
        this.showWinners = false;
      }

      this.winnersLoading = false;
    },
    async winnerRefresh(isV1) {
      if (isV1) {
        this.retreiveV1Winner();
      } else {
        this.retrieveWinners();
      }
    },
    async complete(winnerUuid) {
      this.retrieveWinners();

      if (winnerUuid.data || winnerUuid.data.length === 0) {
        this.$store.commit('SET_CLOSE_BUTTON', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.grand-prize {
  &__inputs {
    input {
      width: 100%;
      padding-left: 0.5rem;
      border-radius: 8px;
    }

    input[disabled='disabled'] {
      padding-left: 0;
      border: 0;
      outline: 0;
      background-color: white !important;
    }
  }
}
</style>
