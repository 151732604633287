<template>
  <b-modal
    :id="dynamicId"
    :title="dynamicTitle"
    @ok="handleOk"
    @cancel="clearForm"
    no-close-on-backdrop
    size="m"
    ok-title="Save"
    ok-variant="success"
    body-class="position-static"
  >
    <Alert variant="red" v-if="submitError" icon="exclamation">The following error occured: {{ errorMessage }}</Alert>
    <b-form class="create-edit-prize" @submit.stop.prevent>
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <b-form-group
        label-for="input-prize-name"
        :invalid-feedback="veeErrors.first('input-prize-name')"
        label-class="mb-0"
      >
        <template slot="label">
          <span class="font-bold">Title <span class="text-red-600 required">*</span></span>
        </template>
        <b-form-input
          name="input-prize-name"
          v-model="name"
          v-validate="'required|min: 3|max: 120'"
          :state="validateState('input-prize-name')"
          data-vv-as="prize"
          trim
        />
      </b-form-group>

      <b-form-group label-for="input-value" :invalid-feedback="veeErrors.first('input-value')" label-class="mb-0">
        <template slot="label">
          <span class="font-bold">Description <span class="text-red-600 required">*</span></span>
        </template>
        <b-form-input
          name="input-value"
          v-model="prizeValue"
          v-validate="'required|min: 3|max: 120'"
          :state="validateState('input-value')"
          data-vv-as="prize value"
          trim
        />
      </b-form-group>

      <b-form-group
        v-if="bulkPrizesEnabled"
        label-for="input-quantity"
        :invalid-feedback="veeErrors.first('input-quantity')"
        label-class="mb-0"
      >
        <template slot="label">
          <span class="font-bold">Quantity</span>
        </template>
        <b-form-input
          name="input-quantity"
          v-model="prizeQuantity"
          v-validate="'integer|min_value:1'"
          :state="validateState('input-quantity')"
          data-vv-as="prize quantity"
          :default="1"
          trim
        />
      </b-form-group>

      <div class="early-bird--toggle">
        <b-form-checkbox v-model="earlyBird" name="early-bird" switch>This is an early bird prize.</b-form-checkbox>
      </div>

      <div v-show="earlyBird" class="mt-4 early-bird">
        <b-form-group class="mt-2 mb-1" :invalid-feedback="veeErrors.first('input-draw-date')" label-class="mb-0">
          <template slot="label">
            <span class="font-bold">Early Bird Cutoff Date <span class="text-red-600 required">*</span></span>
            <div class="mb-2 text-xs">
              All tickets sold before this time will be included in the draw for this prize.
            </div>
          </template>
          <b-form-datepicker
            name="input-draw-date"
            v-model="drawDate"
            :min="minDrawDate"
            :state="validateState('input-draw-date')"
            v-validate="`${earlyBird ? 'required' : ''}`"
            data-vv-as="draw date"
            @hidden="validateDrawDate"
          />
        </b-form-group>

        <b-form-group
          label-for="input-draw-time"
          :invalid-feedback="veeErrors.first('input-draw-time')"
          label-size="sm"
          label-class="mb-0"
          :description="event.timeZone"
        >
          <b-form-timepicker
            name="input-draw-time"
            v-model="drawTime"
            v-validate="`${earlyBird ? 'required' : ''}`"
            :state="validateState('input-draw-time')"
            data-vv-as="draw time"
            size="sm"
            show-seconds
            @hidden="validateDrawDate"
          />
        </b-form-group>

        <b-form-checkbox
          v-model="showFreeTicketsInput"
          name="free-ticket-prize"
          switch
          class="mt-2"
          v-if="freeTicketWinnersEnabled && earlyBird"
          >Free Tickets Prize</b-form-checkbox
        >

        <b-form-group
          v-if="showFreeTicketsInput"
          label-for="input-free-ticket-package-quantity"
          :invalid-feedback="veeErrors.first('input-free-ticket-package-quantity')"
          label-class="mb-0"
          class="mt-2"
        >
          <template slot="label">
            <span class="font-bold">Number of free ticket packages to award</span>
          </template>
          <b-form-input
            name="input-free-ticket-package-quantity"
            v-model="freeTicketPackageQuantity"
            v-validate="'integer|min_value:0'"
            :state="validateState('input-free-ticket-package-quantity')"
            data-vv-as="free ticket package quantity"
            :default="0"
            trim
          />
        </b-form-group>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import Alert from '@/components/ui/Alert';

import { format, isBefore, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { mapGetters } from 'vuex';
import { UnleashKeys, unleashFeatureEnabled } from '@/lib/unleash';

export default {
  components: {
    Alert
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    prizeId: {
      type: String
    }
  },
  computed: {
    dynamicTitle() {
      return this.edit ? 'Edit Prize' : 'Create Prize';
    },
    dynamicId() {
      return this.edit ? `edit-prize-${this.prizeId}` : 'create-prize';
    },
    prize() {
      return this.getPrize(this.prizeId);
    },
    ...mapGetters(['getPrize', 'isEarlyBird'])
  },
  data() {
    return {
      loading: false,
      drawDate: null,
      minDrawDate: this.event.startDate,
      drawTime: null,
      name: null,
      prizeValue: null,
      prizeQuantity: null,
      submitError: false,
      errorMessage: null,
      earlyBird: false,
      bulkPrizesEnabled: false,
      freeTicketWinnersEnabled: false,
      isSuperAdmin: false,
      showFreeTicketsInput: false
    };
  },
  watch: {
    // Watch the earlybird toggle for change. If turn off set time to null.
    earlyBird: function (newVal) {
      if (newVal === false) {
        this.drawDate = null;
        this.drawTime = null;
      }
    }
  },
  async created() {
    this.bulkPrizesEnabled = await unleashFeatureEnabled(UnleashKeys.BulkPrizes, this.event.id);
    this.isSuperAdmin = await unleashFeatureEnabled(UnleashKeys.SupportSuperAdmin);

    // Free ticket winners is enabled if the event has a free ticket package AND the feature flag is true
    this.freeTicketWinnersEnabled =
      this.event.ticketPackages.find((tp) => Number(tp.price) === 0) &&
      (await unleashFeatureEnabled(UnleashKeys.FreeTicketWinners));
  },
  mounted() {
    this.clearForm();

    if (this.edit) {
      // Set values from prize
      this.name = this.prize.name;
      this.prizeValue = this.prize.prizeValue;
      this.earlyBird = this.isEarlyBird(this.prizeId, this.event.endDate);
      this.prizeQuantity = this.prize.quantity;
      this.freeTicketPackageQuantity = this.prize.freeTicketPackageQuantity;

      if (this.prize.drawDate !== null) {
        const { date, time } = this.parseDateTime(this.prize.drawDate, this.event.timeZone);
        this.drawDate = date;
        this.drawTime = time;
      }
    }
  },
  methods: {
    async handleOk(event) {
      event.preventDefault();
      await this.onSubmit();
    },

    async onSubmit() {
      this.submitError = false;

      const valid = await this.$validator.validateAll();
      const drawDateValid = this.validateDrawDate();

      if (!valid || !drawDateValid) {
        return;
      }

      this.loading = true;

      // Set date to null if EB time was set on a previous version but is now no longer EB
      const toUTC = zonedTimeToUtc(`${this.drawDate} ${this.drawTime}`, this.event.timeZone);

      const prizeDrawDate = !this.isSuperAdmin ? isBefore(toUTC, parseISO(this.event.endDate)) : toUTC;

      // If the EB has been updated to Prize null the date/time fields
      if (!prizeDrawDate) {
        this.drawDate = null;
        this.drawTime = null;

        this.$nextTick(() => {
          this.$validator.reset();
        });
      }

      // Build Prize
      const prize = {
        eventId: this.event.id,
        drawDate: prizeDrawDate ? toUTC : null,
        name: this.name,
        prizeValue: this.prizeValue,
        freeTicketPackageQuantity: !this.earlyBird ? 0 : this.freeTicketPackageQuantity || 0
      };

      // Ensure this only gets added for organizations that have the feature enabled
      if (this.bulkPrizesEnabled && this.prizeQuantity) {
        prize.quantity = this.prizeQuantity;
      }

      try {
        if (this.edit) {
          // Edit does not accept Event ID
          delete prize['eventId'];
          await this.$store.dispatch('updatePrize', { prizeId: this.prize.id, prize: prize });
        } else {
          await this.$store.dispatch('addPrize', prize);
        }

        this.updateList();
        this.$bvModal.hide(this.dynamicId);
      } catch (error) {
        this.submitError = true;
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loading = false;
      }
    },
    // Move to Lib
    parseDateTime(datetime, timeZone) {
      const dateUtc = this.parseISO(datetime);
      const zonedDate = utcToZonedTime(dateUtc, timeZone);

      const newDate = format(zonedDate, 'yyyy-MM-dd');
      const newTime = format(zonedDate, 'HH:mm:ss');

      return { date: newDate, time: newTime };
    },

    clearForm() {
      this.name = null;
      this.prizeValue = null;
      this.submitError = false;
      this.errorMessage = null;
      this.earlyBird = false;
      this.drawDate = null;
      this.drawTime = null;
      this.freeTicketPackageQuantity = null;

      if (this.edit) {
        // Set values from prize
        this.name = this.prize.name;
        this.prizeValue = this.prize.prizeValue;
        this.earlyBird = this.isEarlyBird(this.prizeId, this.event.endDate);
        this.freeTicketPackageQuantity = this.prize.freeTicketPackageQuantity;

        if (this.prize.drawDate !== null) {
          const { date, time } = this.parseDateTime(this.prize.drawDate, this.event.timeZone);
          this.drawDate = date;
          this.drawTime = time;
        }
      }

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    async updateList() {
      await this.$store.dispatch('listPrizes', this.event.id);
    },
    validateDrawDate() {
      const eventStartDateUtc = this.parseISO(this.event.startDate);
      const eventStartDate = utcToZonedTime(eventStartDateUtc, this.event.timeZone);

      const pickerDateTime = this.parseISO(`${this.drawDate} ${this.drawTime}`);

      if (isBefore(pickerDateTime, eventStartDate)) {
        this.veeFields['input-draw-date']['dirty'] = true;
        this.veeFields['input-draw-date']['invalid'] = true;
        this.veeFields['input-draw-time']['dirty'] = true;
        this.veeFields['input-draw-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-draw-time',
          msg: 'The Draw Date cannot be earlier than the Ticket Sales Start Date'
        });
      } else {
        return true;
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  // bg-green-600: #439547
  border-color: #439547;
  background-color: #439547;
}

.create-edit-prize .btn-sm {
  padding: 0.25rem 1rem;
}

.create-edit-prize .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.modal-footer .btn.btn-success {
  background-color: #439547;

  &:hover {
    color: #439547;
    border-color: #439547;
    background-color: #ffffff;
  }
}
</style>
