<template>
  <div>
    <b-button v-b-modal="'edit-raffle-modal'" variant="outline-secondary" size="sm" v-if="canEditRaffle">
      <font-awesome-icon :icon="['far', 'pen-to-square']" />
      <span class="pl-2">Edit Raffle</span>
    </b-button>
    <b-modal
      :id="'edit-raffle-' + modalId"
      title="Edit Raffle"
      v-model="modalShow"
      @hidden="resetForm"
      @ok="handleOk"
      ok-title="Save"
      no-close-on-backdrop
      :no-enforce-focus="noEnforceFocus"
      size="xl"
    >
      <b-spinner v-if="basicForm.loading">Loading...</b-spinner>
      <b-form v-else @submit.stop.prevent="onSubmit">
        <b-alert :show="status === 'active'" variant="warning">
          This raffle is {{ status }}. Before modifying any details, ensure you are in compliance with local
          regulations. Some fields have been disabled for regulatory purposes.
        </b-alert>
        <b-alert :show="status === 'ended' || status === 'closed'" variant="warning">
          This raffle is {{ status }}. Some fields have been disabled to comply with local regulations.
        </b-alert>
        <b-alert :show="editRaffleFailed" fade dismissible class="alert alert-danger">{{ errorMessage }}</b-alert>
        <b-tabs>
          <b-tab title="Basic" class="pt-3 pl-1">
            <EditRaffleBasic />
          </b-tab>
          <b-tab title="Ticket Packages" class="pt-3 pl-1">
            <TicketPackages
              :event="event"
              @tickets-limit="updateTicketsLimit"
              @tickets-threshold="updateTicketsThreshold"
            />
          </b-tab>
          <b-tab title="Email" class="pt-3 pl-1" v-if="showEditRaffleEmail">
            <EditRaffleEmail />
          </b-tab>
          <b-tab title="Fees" class="pt-3 pl-1" v-if="showEditRaffleFeesTab">
            <EditRaffleFees />
          </b-tab>
          <b-tab title="In Support Of" class="pt-3 pl-1" v-if="canEditRaffleEventMembers" lazy>
            <EditRaffleInSupportOf />
          </b-tab>
          <b-tab title="Ticker" class="pt-3 pl-1" v-if="showEditRaffleTicker" lazy>
            <EditRaffleTicker />
          </b-tab>
          <b-tab title="InVenue" class="pt-3 pl-1" v-if="showEditRaffleInVenue" lazy>
            <EditRaffleInVenue />
          </b-tab>
          <b-tab title="Advanced" class="pt-3 pl-1" v-if="showEditRaffleAdvanced" lazy>
            <EditRaffleAdvanced />
          </b-tab>
          <b-tab title="RB Admin Settings" class="pt-3 pl-1" v-if="showEditRaffleRBAdminSettings" lazy>
            <EditRaffleRBAdmin />
          </b-tab>
          <b-tab title="Tags" class="pt-3 pl-1" v-if="showEditRaffleTags" lazy>
            <AddTagForm
              :system="true"
              :organizationId="event.organizationId"
              :eventId="event.id"
              @onError="onError"
              @onAddTag="onTagUpdate"
            />

            <ViewTags
              :organizationId="event.organizationId"
              :eventId="event.id"
              :tags="event.tags"
              @onError="onError"
              @onDeleteTag="onTagUpdate"
            />
          </b-tab>
        </b-tabs>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import EditRaffleBasic from '@/components/forms/EditRaffleBasic';
import EditRaffleEmail from '@/components/forms/EditRaffleEmail';

import EventServiceV2 from '@/lib/event-service-v2';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import ProvinceStateService from '@/lib/province-state-view-service';
import EditRaffleFees from '@/components/forms/EditRaffleFees';
import EditRaffleInSupportOf from '@/components/forms/EditRaffleInSupportOf';
import EditRaffleTicker from '@/components/forms/EditRaffleTicker';
import EditRaffleInVenue from '@/components/forms/EditRaffleInVenue';
import EditRaffleAdvanced from '@/components/forms/EditRaffleAdvanced';
import EditRaffleRBAdmin from '@/components/forms/EditRaffleRBAdmin';
import TicketPackages from '@/components/TicketPackages';
import AddTagForm from '@/components/forms/AddTagForm';
import ViewTags from '@/components/ViewTags';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    EditRaffleBasic,
    EditRaffleEmail,
    EditRaffleFees,
    EditRaffleInSupportOf,
    EditRaffleTicker,
    EditRaffleInVenue,
    EditRaffleAdvanced,
    EditRaffleRBAdmin,
    TicketPackages,
    AddTagForm,
    ViewTags
  },
  props: {
    modalId: {
      type: String
    },
    event: {
      type: Object
    }
  },
  computed: {
    basicForm() {
      return this.$store.getters.getBasicForm;
    },
    emailForm() {
      return this.$store.getters.getEmailForm;
    },
    feesForm() {
      return this.$store.getters.getFeesForm;
    },
    inSupportOfForm() {
      return this.$store.getters.getInSupportOfForm;
    },
    settings() {
      return this.$store.getters.getSettings;
    },
    tickerForm() {
      return this.$store.getters.getTickerForm;
    },
    invenueForm() {
      return this.$store.getters.getInvenueForm;
    },
    advancedForm() {
      return this.$store.getters.getAdvancedForm;
    },
    rbAdminForm() {
      return this.$store.getters.getRbAdminForm;
    },
    datesValid() {
      return this.$store.getters.getDatesValid;
    },
    status() {
      return this.$store.getters.getStatus;
    },
    noEnforceFocus() {
      return this.$store.getters.getNoEnforceFocus;
    }
  },
  data() {
    return {
      modalShow: false,
      editRaffleFailed: false,
      errorMessage: null,
      ticketFooterQRCode: '',
      ticketFooterQRCodeImage: '',
      showEditRaffleTicker: false,
      showEditRaffleTags: false,
      canEditRaffleEventMembers: false,
      showEditRaffleAdvanced: false,
      showEditRaffleEmail: false,
      showEditRaffleInVenue: false,
      showEditRaffleFeesTab: false,
      showEditRaffleRBAdminSettings: false,
      canEditRaffle: false
    };
  },
  async created() {
    await this.resetForm();
  },
  async mounted() {
    await this.$store.dispatch('fetchCategoryOptions');
    this.showEditRaffleTicker = await unleashFeatureEnabled(UnleashKeys.EditRaffleTicker);
    this.showEditRaffleTags = await unleashFeatureEnabled(UnleashKeys.EditRaffleTags);
    this.canEditRaffleEventMembers = await unleashFeatureEnabled(UnleashKeys.EditRaffleEventMembers);
    this.showEditRaffleAdvanced = await unleashFeatureEnabled(UnleashKeys.EditRaffleAdvanced);
    this.showEditRaffleEmail = await unleashFeatureEnabled(UnleashKeys.EditRaffleEmail);
    this.showEditRaffleInVenue = await unleashFeatureEnabled(UnleashKeys.EditRaffleInVenue);
    this.showEditRaffleFeesTab = await unleashFeatureEnabled(UnleashKeys.EditRaffleFeesTab);
    this.showEditRaffleRBAdminSettings = await unleashFeatureEnabled(UnleashKeys.EditRaffleRBAdminSettings);
    this.canEditRaffle = await unleashFeatureEnabled(UnleashKeys.EditRaffle);
  },
  methods: {
    async updateTicketsLimit(ticketLimit) {
      await this.$store.dispatch('setSettings', { ...this.settings, ticketLimit: Number(ticketLimit) });
    },
    async updateTicketsThreshold(ticketThreshold) {
      await this.$store.dispatch('setSettings', { ...this.settings, ticketThreshold: Number(ticketThreshold) });
    },
    closeTrumbowygModal() {
      const trumboModal = document.getElementsByClassName('trumbowyg-modal');

      if (trumboModal.length != 0) {
        trumboModal[0].classList.add('d-none');
      }
    },
    async resetForm() {
      this.closeTrumbowygModal();
      await this.$store.dispatch('fetchEvent', this.event.id);
      await this.$store.dispatch('fetchUserOrganization');
      await this.$store.dispatch('fetchProvinceStateView');
      await this.$store.dispatch('setStatus', this.event.status);

      this.provinceOptions = this.$store.state['editRaffle'].provinceStateView.provinceOptions;
      this.dropdownLabel = this.$store.state['editRaffle'].provinceStateView.dropdownLabel;
      this.sessionUser = await getAuth().sessionUser();
      this.organization = await OrganizationServiceV2.retrieveOrganization(this.sessionUser.organizationUuid);
      this.provinceStateView = ProvinceStateService.getEditRaffleModalView(this.organization.country);

      await this.$store.dispatch('setFeesForm', {
        ...this.feesForm,
        raffleboxOrderFeeDollars: this.$store.state['editRaffle'].raffle.raffleboxOrderFeeCents / 100,
        raffleboxFeeFlatRateDollars: this.$store.state['editRaffle'].raffle.raffleboxFeeFlatRateCents / 100
      });

      await this.$store.dispatch('fetchCategoryOptions');
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      let parentEventId;

      if (!this.datesValid) {
        return;
      }
      await this.$store.dispatch('setRaffleDates');

      if (this.$store.state.isRbAdmin) {
        parentEventId = this.rbAdminForm.parentEvent?.id ? this.rbAdminForm.parentEvent.id : null;
      }
      try {
        if (this.invenueForm.ticketFooterQRCode === '' || this.invenueForm.ticketFooterQRCodeImage === '') {
          this.ticketFooterQRCode = '';
          this.ticketFooterQRCodeImage = '';
        } else {
          this.ticketFooterQRCode = this.invenueForm.ticketFooterQRCode;
          this.ticketFooterQRCodeImage = this.invenueForm.ticketFooterQRCodeImage;
        }
        const data = {
          name: this.basicForm.name,
          description: this.basicForm.description,
          rules: this.basicForm.rules,
          showRules: this.rbAdminForm.rulesEnabled,
          startDate: this.basicForm.startDateTime,
          endDate: this.basicForm.endDateTime,
          drawDate: this.basicForm.drawDateTime,
          licenseNumber: this.basicForm.licenseNumber,
          shortlink: this.basicForm.shortlink,
          showLiveRaffles: this.basicForm.showLiveRaffles,
          drawType: this.rbAdminForm.drawType,
          province: this.basicForm.province,
          drawLocation: this.basicForm.drawLocation,
          disablePostalCheck: !this.rbAdminForm.postalCodeCheck,
          maxJackpot: this.advancedForm.maxJackpot,
          logoUrl: this.basicForm.logoUrl,
          settings: this.settings,
          supportStatsShow: this.inSupportOfForm.supportStatsShow,
          supportStatsActive: this.inSupportOfForm.supportStatsActive,
          ticketNumbersShow: !this.rbAdminForm.ticketNumbersShow,
          tickerHeader: this.tickerForm.tickerHeader,
          tickerFooter: this.tickerForm.tickerFooter,
          tickerUrl: this.tickerForm.tickerUrl,
          tickerLogo: this.tickerForm.tickerLogo,
          category: this.basicForm.category,
          startTicketNum: this.advancedForm.startingTicketNumber,
          startingPotCents: Number(this.advancedForm.startingPot) * 100,
          minimumJackpotDisplayCents: Number(this.advancedForm.minimumJackpotDisplay) * 100,
          frequency: this.basicForm.frequency,
          ticketFooterQRCode: this.ticketFooterQRCode,
          ticketFooterQRCodeImage: this.ticketFooterQRCodeImage,
          ticketFooterImage: this.invenueForm.ticketFooterImage,
          invenueTicketFooter: this.invenueForm.invenueTicketFooter,
          parentEventId,
          salesRepId: this.basicForm.salesRepId,
          statementDescriptor: this.rbAdminForm.statementDescriptor,
          licenseUrl: this.basicForm.licenseUrl,
          supportStatsForceSelectionEnabled: this.inSupportOfForm.supportStatsForceSelectionEnabled,
          isHomeLottery: this.rbAdminForm.isHomeLottery
        };

        // Only submit license url to the API if we are showing the field
        if (this.basicForm.showLicenseUpload) {
          data.licenseUrl = this.basicForm.licenseUrl;
        }
        // Only add fee data to requests if RB_Admin
        if (this.$store.state.isRbAdmin) {
          data.raffleboxFeePercent = Number(this.feesForm.raffleboxFeePercent);
          data.raffleboxOrderFeeCents = Math.round(this.feesForm.raffleboxOrderFeeDollars * 100);
          data.raffleboxFeeFlatRateCents = Math.round(this.feesForm.raffleboxFeeFlatRateDollars * 100);
        }

        // require purchase is an rbadmin only setting and can't be submitted
        if (!this.$store.state.isRbAdmin) {
          delete data.settings.requirePurchase;
        }

        if (this.$store.state.isAccountOwner || this.$store.state.isRbAdmin || this.$store.state.isRaffleAdmin) {
          data.processingFeePercent = Number(this.feesForm.processingFeePercent);
        }

        if (this.emailForm.emailTicketBody != null) {
          data.emailTicketBody = this.emailForm.emailTicketBody;
        }

        if (this.emailForm.emailTicketFooter != null) {
          data.emailTicketFooter = this.emailForm.emailTicketFooter;
        }

        if (this.inSupportOfForm.supportStatsLabel != null) {
          data.supportStatsLabel = this.inSupportOfForm.supportStatsLabel;
        }

        await EventServiceV2.updateEvent(this.event.id, data);
        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.editRaffleFailed = true;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async onTagUpdate() {
      this.$emit('updateEventTags', this.event.id);
    },
    onError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  provide() {
    return { parentValidator: this.$validator };
  }
};
</script>

<style lang="scss">
.color-picker {
  display: inline-block;
}

.color-picker:hover .color-picker__picker {
  opacity: 1;
  z-index: 100;
}

.color-picker__picker {
  position: absolute;
  bottom: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s ease-in-out;
}

.color-picker__button {
  margin-right: 9px;
}

.color-picker__button span {
  display: block;
  font-size: 12px;
  padding: 4px;
  color: #ffffff;
  border-radius: 4px;
}

.vc-chrome-alpha-wrap {
  display: none !important;
}

.rb-field-group {
  padding: 2rem 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.rb-field-group:last-child {
  border-bottom: 0;
}

.rb-field-group .input-xs-width {
  width: 7rem;
}

.rb-field-group .input-sm-width {
  width: 15rem;
}

.rb-field-group .input-lg-width {
  max-width: 40rem;
}

.rb-field-group .input-lg-with-button {
  max-width: 34rem;
}

.rb-field-group .form-group {
  margin-right: 2rem;
}

.trumbowyg-box {
  margin-top: 0;
}

.modal-xl {
  max-width: 1200px;
}

.input-license-upload {
  label {
    margin-bottom: 0 !important;
  }

  .custom-file-label {
    border-radius: 8px;
    min-width: 100%;
  }
}

.center-spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
</style>
