<template>
  <div>
    <b-alert :show="successMessage.show" @dismissed="successMessage.show = 0" class="mt-3" variant="success" fade>
      {{ successMessage.message }}
    </b-alert>
    <b-alert :show="errorMessage.show" @dismissed="errorMessage.show = 0" variant="danger" fade>
      {{ errorMessage.message }}
    </b-alert>
    <b-form @submit.stop.prevent="onSubmit" v-if="canModify()" class="mt-4" inline>
      <b-form-group
        label="# of Tickets"
        label-for="input-num-tickets"
        :invalid-feedback="veeErrors.first('input-num-tickets')"
        label-class="sr-only"
        class="mr-2"
        description="Number of Tickets"
      >
        <b-form-input
          name="input-num-tickets"
          v-model="numTickets"
          type="number"
          v-validate="{ required: true, max_value: 800 }"
          :state="validateState('input-num-tickets')"
          aria-describedby="input-num-tickets-feedback"
          placeholder="# of Tickets"
          class="input-sm"
          data-vv-as="number of tickets"
        ></b-form-input>
      </b-form-group>
      <div>
        <label for="input-price" class="sr-only">Price</label>
        <b-form-group description="Price" prepend="$" append=".00" class="mr-2">
          <b-form-input
            name="input-price"
            v-model="price"
            type="number"
            v-validate="{ required: true }"
            :state="validateState('input-price')"
            placeholder="Price"
            class="input-sm"
            data-vv-as="price"
          ></b-form-input>
        </b-form-group>
        <b-form-invalid-feedback id="input-price-feedback" :state="validateState('input-price')">{{
          veeErrors.first('input-price')
        }}</b-form-invalid-feedback>
      </div>

      <b-form-group
        label="Series"
        label-for="input-series"
        description="Series"
        :invalid-feedback="veeErrors.first('input-series')"
        label-class="sr-only"
        class="mr-2"
        ><b-form-select
          name="input-series"
          v-model="series"
          :options="seriesOptions"
          v-validate="{ required: true }"
          :state="validateState('input-series')"
          data-vv-as="raffle"
          style="min-width: 5rem"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="Limit"
        label-for="input-limit"
        description="Limit (optional)"
        :invalid-feedback="veeErrors.first('input-limit')"
        label-class="sr-only"
        class="mr-2"
      >
        <b-form-input
          name="input-limit"
          v-model="limit"
          type="number"
          v-validate="{}"
          :state="validateState('input-limit')"
          aria-describedby="input-limit-feedback"
          placeholder="Limit"
          class="input-sm"
          data-vv-as="limit"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-class="sr-only"
        description="Active"
        class="mr-4"
        v-show="isEdit && canEditTicketPackages"
        label-for="active-button"
      >
        <b-form-checkbox v-model="active" name="active-button" switch> </b-form-checkbox>
      </b-form-group>
      <div>
        <b-button variant="outline-primary" size="md" type="submit">
          <span v-if="isEdit">Edit</span>
          <span v-else>Add</span>
        </b-button>
      </div>
    </b-form>
    <b-table
      show-empty
      id="table"
      class="ticket-packages-table mt-4"
      striped
      :fields="visibleFields"
      :items="tickets"
      foot-clone
    >
      <template v-slot:cell(price)="row">
        {{ formatCurrency(row.item.price) }}
      </template>
      <template v-slot:cell(total)="row">
        {{ row.item.limit ? formatCurrency(row.item.limit * row.item.price) : '-' }}
      </template>
      <template v-slot:cell(availableTickets)="row">
        {{ row.item.limit ? row.item.availableTickets : '-' }}
      </template>
      <template v-slot:cell(edit)="row">
        <span @click="editTicketPackage(row.item)" class="table-actions"
          ><i class="fa-regular fa-pen-to-square mr-3"></i
        ></span>
        <span @click="deleteTicketPackage(row.item)" class="table-actions"><i class="fa-solid fa-trash-can" /></span>
      </template>
      <template v-slot:foot(numTickets)>
        <span></span>
      </template>
      <template v-slot:foot(price)>
        <span></span>
      </template>
      <template v-slot:foot(series)>
        <span></span>
      </template>
      <template v-slot:foot(limit)>
        <span></span>
      </template>
      <template v-slot:foot(issued)>
        <span></span>
      </template>
      <template v-slot:cell(soldOut)="row">
        <span>
          <b-badge v-if="row.item.soldOut" variant="danger">Sold Out</b-badge>
        </span>
      </template>
      <template v-slot:cell(active)="row">
        <span>
          <b-badge v-if="row.item.active" variant="success">Active</b-badge>
          <b-badge v-if="!row.item.active" variant="secondary">Inactive</b-badge>
        </span>
      </template>
      <template v-slot:foot(total)>
        <span>{{ formatCurrency(totalValue) }}</span>
      </template>
      <template v-slot:foot(availableTickets)>
        <span>{{ totalTicketsAvailable }}</span>
      </template>
      <template v-slot:foot(edit)>
        <span></span>
      </template>
    </b-table>

    <hr />
    <div v-if="canSetTicketLimit" class="mt-9">
      <h1 class="text-2xl font-medium">Ticket Limit Configuration</h1>
      <div class="sm-wrap">
        <b-form-group
          label-for="input-tickets-limit"
          :invalid-feedback="veeErrors.first('input-tickets-limit')"
          class="mw-100"
        >
          <template slot="label">
            Tickets Limit
            <sup
              class="inline-block"
              v-b-tooltip.hover.top="
                'This limits the total number of tickets available for sale on this draw. Set to 0 for no limit.'
              "
            >
              <span class="fa-solid fa-circle-info"></span>
            </sup>
          </template>
          <b-form-input
            name="input-tickets-limit"
            @input="onInputTicketsLimit"
            :value="ticketsLimit"
            v-validate="{ min_value: 0, integer: true }"
            type="number"
            :state="validateState('input-tickets-limit')"
            aria-describedby="input-tickets-limit-feedback"
            data-vv-as="Tickets Limit"
            trim
            style="max-width: 27rem"
          />
        </b-form-group>
        <b-form-group
          v-if="isHomeLottery()"
          label-for="input-tickets-limit"
          :invalid-feedback="veeErrors.first('input-tickets-limit')"
          class="mw-100"
        >
          <template slot="label">
            Tickets Threshold
            <sup
              class="inline-block"
              v-b-tooltip.hover.top="
                'This limits the total number of tickets available for online sale in this draw. Online sales will be disabled when the threshold is reached. Set to 0 for no limit.'
              "
            >
              <span class="fa-solid fa-circle-info"></span>
            </sup>
          </template>
          <b-form-input
            name="input-tickets-threshold"
            @input="onInputTicketsThreshold"
            :value="ticketsThreshold"
            v-validate="{ min_value: 0, integer: true }"
            type="number"
            :state="validateState('input-tickets-threshold')"
            aria-describedby="input-tickets-threshold-feedback"
            data-vv-as="Tickets Threshold"
            trim
            style="max-width: 27rem"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import TicketPackageService from '@/lib/ticket-package-service';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  props: {
    event: {
      type: Object,
      default: () => {
        return {
          id: null,
          status: null
        };
      }
    }
  },
  computed: {
    visibleFields() {
      return this.table.fields.filter((field) => field.visible);
    },
    ticketsLimit() {
      return this.event.settings.ticketLimit || 0;
    },
    ticketsThreshold() {
      return this.event.settings.ticketThreshold || 0;
    }
  },
  data() {
    return {
      seriesOptions: ['A', 'B', 'C', 'D', 'E'],
      table: {
        fields: [
          {
            key: 'numTickets',
            label: '# of Tickets',
            visible: true
          },
          {
            key: 'price',
            label: 'Price',
            visible: true
          },
          {
            key: 'series',
            label: 'Series',
            visible: true
          },
          {
            key: 'limit',
            label: 'Limit',
            visible: true
          },
          {
            key: 'issued',
            label: 'Issued',
            visible: true
          },
          {
            key: 'total',
            label: '$ Total',
            visible: true
          },
          {
            key: 'availableTickets',
            label: 'Available Tickets',
            visible: true
          },
          {
            key: 'active',
            label: 'Active',
            visible: true
          },
          {
            key: 'soldOut',
            label: 'Sold Out',
            visible: true
          },
          {
            key: 'edit',
            label: 'Edit/Delete',
            visible: this.canModify()
          }
        ]
      },
      tickets: [],
      successMessage: {
        message: null,
        visible: 0
      },
      errorMessage: {
        message: null,
        visible: 0
      },
      numTickets: null,
      price: null,
      series: null,
      limit: null,
      active: false,
      isEdit: false,
      ticketPackageId: null,
      totalValue: '',
      availableTickets: '',
      totalTicketsAvailable: 0,
      canSetTicketLimit: false,
      canEditTicketPackages: false
    };
  },
  watch: {
    tickets() {
      this.updateTotalValue();
    }
  },
  async mounted() {
    this.getTicketPackages();
    this.canSetTicketLimit = await unleashFeatureEnabled(UnleashKeys.SetTicketLimit);
    this.canEditTicketPackages = await unleashFeatureEnabled(UnleashKeys.EditTicketPackages);
    this.seriesOptions = (await unleashFeatureEnabled(UnleashKeys.AtozTicketPackages))
      ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
      : 'ABCDE'.split('');
  },
  methods: {
    showError(message) {
      this.clearError();

      this.$nextTick(() => {
        this.errorMessage.message = message;
        this.errorMessage.show = 5;
      });
    },

    clearError() {
      this.errorMessage.show = 0;
      this.errorMessage.message = '';
    },
    canModify() {
      if (this.event.status === 'pending') {
        return true;
      } else {
        return false;
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isHomeLottery() {
      return this.event.isHomeLottery;
    },
    updateTotalValue() {
      let localTotalTickets = 0;
      this.totalValue = 0.0;
      this.tickets.forEach((ticket) => {
        this.totalValue += ticket.price * ticket.limit;
        localTotalTickets += ticket.availableTickets;
      });
      this.totalTicketsAvailable = localTotalTickets;
    },
    resetForm() {
      this.numTickets = null;
      this.price = null;
      this.series = null;
      this.limit = null;
      this.active = false;
      this.successMessage.message = null;
      this.successMessage.show = 0;
      this.errorMessage.message = null;
      this.errorMessage.show = 0;
      this.isEdit = false;
      this.updateTotalValue();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async getTicketPackages() {
      // If there is no event id then we are likely creating the raffle versus editing
      if (this.event.id) {
        const results = await TicketPackageService.listTicketPackages(this.event.id);
        this.tickets = results;
      }
    },
    async deleteTicketPackage(ticketPackage) {
      if (this.event.id) {
        try {
          await TicketPackageService.deleteTicketPackage(ticketPackage.id);
          this.getTicketPackages();
        } catch (error) {
          const errorMessage = error.response?.data?.errors?.[0]?.message || error.message;
          this.showError(errorMessage);
        }
      } else {
        const ticket = this.tickets.find((ticketPackage) => ticketPackage.series === ticketPackage.series);
        const index = this.tickets.indexOf(ticket);
        if (index >= 0) {
          this.tickets.splice(index, 1);
        }
      }
    },
    editTicketPackage(item) {
      this.isEdit = true;
      this.numTickets = item.numTickets;
      this.price = parseInt(item.price);
      this.series = item.series;
      this.limit = item.limit;
      this.active = item.active;
      this.ticketPackageId = item.id;
    },
    validateSeries() {
      let filteredTPs = [];

      if (this.isEdit === true) {
        const excludeCurrentTP = this.tickets.filter((tp) => tp.id !== this.ticketPackageId);
        filteredTPs = excludeCurrentTP.filter((tp) => tp.series === this.series);
      } else {
        filteredTPs = this.tickets.filter((tp) => tp.series === this.series);
      }

      if (filteredTPs.length > 0) {
        this.veeFields['input-series']['dirty'] = true;
        this.veeFields['input-series']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-series',
          msg: 'The series name must be unique'
        });
        return false;
      } else {
        return true;
      }
    },
    async validate() {
      const numberOfTickets = await this.$validator.validate('input-num-tickets');
      const price = await this.$validator.validate('input-price');
      const series = await this.$validator.validate('input-series');
      const limit = await this.$validator.validate('input-limit');

      return numberOfTickets && price && series && limit;
    },
    async onSubmit() {
      const sessionUser = await getAuth().sessionUser();
      const isRbAdmin = sessionUser.roles.includes('RB Admin');

      const customValidate = await this.validate();
      const validSeries = await this.validateSeries();
      if (!customValidate || !validSeries) {
        return;
      }

      const ticketPackageUpdate = {
        eventId: this.event.id,
        numTickets: this.numTickets,
        price: parseFloat(this.price).toFixed(2),
        series: this.series,
        limit: this.limit | 0,
        active: this.active
      };
      const body = {
        eventId: this.event.id,
        numTickets: this.numTickets,
        price: parseFloat(this.price).toFixed(2),
        series: this.series,
        limit: this.limit | 0
      };

      if (!isRbAdmin) {
        delete ticketPackageUpdate.active;
      }

      if (this.isEdit === true) {
        if (this.event.id) {
          try {
            delete ticketPackageUpdate.eventId;
            await TicketPackageService.updateTicketPackage(ticketPackageUpdate, this.ticketPackageId);
            this.getTicketPackages();
            this.resetForm();
            this.successMessage.message = 'Ticket package updated!';
            this.successMessage.show = 5;
          } catch (error) {
            const errorMessage = error.response?.data?.errors?.[0]?.message || error.message;
            this.showError(errorMessage);
          }
        } else {
          // probably hasn't been created so lets just virtually edit them

          const ticketPackage = this.tickets.find((ticketPackage) => ticketPackage.series === this.series);
          if (ticketPackage) {
            ticketPackage.numTickets = this.numTickets;
            ticketPackage.limit = this.limit | 0;
            ticketPackage.price = parseFloat(this.price);
            ticketPackage.active = this.active;
          }
        }
      } else if (this.event.id) {
        // Event exists so add the package
        try {
          await TicketPackageService.createTicketPackage(body);
          this.getTicketPackages();
          this.resetForm();
          this.successMessage.message = 'Successfully created ticket package!';
          this.successMessage.show = 5;
        } catch (error) {
          const errorMessage = error.response?.data?.errors?.[0]?.message || error.message;
          this.showError(errorMessage);
        }
      } else {
        // probably hasn't been created so lets just virtually add them
        this.tickets.push({
          numTickets: this.numTickets,
          price: parseFloat(this.price),
          series: this.series,
          limit: this.limit | 0
        });
      }

      this.numTickets = null;
      this.active = false;
      this.price = null;
      this.series = null;
      this.limit = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    onInputTicketsLimit(ticketsLimit) {
      this.$emit('tickets-limit', ticketsLimit);
    },
    onInputTicketsThreshold(ticketsThreshold) {
      this.$emit('tickets-threshold', ticketsThreshold);
    }
  }
};
</script>

<style scoped>
.form-group,
.input-sm {
  max-width: 138px;
}

.form-inline {
  align-items: self-start;
}

.table-actions {
  cursor: pointer;
}

.rb-field-group {
  padding: 2rem 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}
</style>
