<template>
  <div class="mb-4 prize-list-item">
    <div
      class="flex flex-nowrap items-center min-h-20 rounded-lg bg-white border border-neutral-200 prize-list-item__main"
      v-if="!loadingWinners"
    >
      <div class="p-4 cursor-move prize-list-item--grip">
        <font-awesome-icon class="text-2xl" :icon="['far', 'grip-dots-vertical']" />
      </div>

      <div
        class="
          w-6/12
          xxl:w-7/12
          py-4
          pr-4
          flex flex-col
          xl:flex-row
          items-start
          xl:items-center
          mr-auto
          prize-list-item__titles
        "
      >
        <div class="xl:w-4/12 pr-4 prize-list-item__title">
          <div class="font-bold">
            {{ prize.name }}
          </div>
          <div class="flex">
            <div class="ml-3" v-if="prize.quantity > 1">
              <Badge variant="tertiary" class="mr-auto flex font-bold" :title="`${prize.quantity} prizes available`">
                <div class="mr-1"><font-awesome-icon :icon="['far', 'gift']" /></div>
                <div class="align-middle h-full">{{ prize.quantity.toString() }}</div>
              </Badge>
            </div>
            <div class="ml-3" v-if="prize.freeTicketPackageQuantity > 0">
              <Badge
                variant="success"
                class="mr-auto flex font-bold"
                :title="`Each winner receives ${prize.freeTicketPackageQuantity} free ticket package(s)`"
              >
                <div class="mr-1"><font-awesome-icon :icon="['fa', 'ticket']" /></div>
                <div class="h-full align-middle">{{ prize.freeTicketPackageQuantity }}</div>
              </Badge>
            </div>
          </div>
        </div>

        <div class="xl:w-8/12">{{ prize.prizeValue }}</div>
      </div>

      <div class="w-6/12 xxl:w-5/12 py-4 flex">
        <div class="flex justify-between items-center mr-auto">
          <PrizeWinnerBadge v-if="getPrizeState(prize.id, 'confirmed')" :winner="winner" />

          <div
            v-else-if="getPrizeState(prize.id, 'pending')"
            class="bg-yellow-600 text-green-900 px-4 py-2 rounded-full prize-list-item__winner-pending"
          >
            Winner Pending
          </div>

          <div v-else>
            <EarlyBirdIcon v-if="iconDisplay" class="w-7 mr-4 inline-block" />
            <font-awesome-icon v-else class="text-xl text-green-600 mr-4" :icon="['far', 'gift']" />
            <DateTimeDisplay v-if="prizeDrawDate" :date-time="prizeDrawDate" :time-zone="event.timeZone" />
          </div>
        </div>

        <div class="pr-4 self-center text-center prize-list-item__controls">
          <div
            v-if="
              isSuperAdmin ||
              (displayControls && !winner && !displayPickWinner(getPrizeWinners(prize.id), prize.drawDate))
            "
            class="prize-list-item__button-wrap flex"
          >
            <button
              @click="edit(prize.id)"
              class="p-2 outline outline-offset-2 outline-1 rounded-lg prize-list-item--edit"
            >
              <font-awesome-icon class="text-xl" :icon="['far', 'pen-to-square']" />
            </button>

            <DeletePrizeModal v-if="hasUnpickedWinner" :prize="prize" />

            <CreateEditPrizeModal :prizeId="prize.id" :event="event" :edit="true" />
          </div>

          <PickWinnerModal
            v-if="showPickWinnerModal"
            :prizeId="prize.id"
            :prizeQuantity="prize.quantity"
            :eventId="event.id"
          />

          <button
            v-if="getPrizeWinners(prize.id).length && prize.quantity === 1"
            class="px-4 py-1 prize-list-item--drawer-toggle"
            @click="toggleDrawer"
          >
            <font-awesome-icon v-if="drawerOpen" class="text-2xl" :icon="['far', 'chevron-up']" />
            <font-awesome-icon v-else class="text-2xl" :icon="['far', 'chevron-down']" />
          </button>

          <div class="group relative inline-block w-32 mt-3" v-if="showProgressBar(prize.id)">
            <ProgressBar
              :id="`progressBar-${prize.id}`"
              variant="green"
              heigth="small"
              :total="prize.quantity"
              :progress="prize.picksCompleted"
            />
            <b-tooltip :target="`progressBar-${prize.id}`" triggers="hover">
              {{ toolTipText }}
            </b-tooltip>
          </div>

          <div v-if="showDownloadButton">
            <DownloadFile
              :retrieveData="downloadBatchPickWinnerReport"
              @error="exportError"
              filename="winners-report.csv"
            >
              <template #default="{ downloadFile }">
                <BaseButton
                  variant="success-outline"
                  icon="arrow-down-to-line"
                  :loading="buttonLoading"
                  @click.native="downloadFile"
                  >Winners</BaseButton
                >
              </template>
            </DownloadFile>
          </div>
        </div>
      </div>
    </div>
    <CollapsibleDrawer :controls="false" :externalToggle="drawerOpen" :initial="false" v-if="!loadingWinners">
      <PrizeDrawerContent
        v-if="!loadingWinners"
        :prize="prize"
        :time-zone="event.timeZone"
        :winners="getPrizeWinners(prize.id)"
        :winner="winner"
        @complete="complete"
      />
    </CollapsibleDrawer>
  </div>
</template>

<script>
import CollapsibleDrawer from '@/components/ui/CollapsibleDrawer';
import CreateEditPrizeModal from '@/components/modals/CreateEditPrizeModal';
import DateTimeDisplay from '@/components/ui/DateTimeDisplay';
import DeletePrizeModal from '@/components/modals/DeletePrizeModal';
import EarlyBirdIcon from '@/components/ui/EarlyBirdIcon';
import PickWinnerModal from '@/components/modals/PickWinnerModal';
import Badge from '@/components/rbComponents/Badge.vue';
import PrizeDrawerContent from '@/components/prizes/PrizeDrawerContent';
import PrizeWinnerBadge from '@/components/prizes/PrizeWinnerBadge';
import ProgressBar from '@/components/ui/ProgressBar';
import DownloadFile from '@/components/DownloadFile';
import WinnerServiceV2 from '@/lib/winner-service-v2';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

import { mapGetters } from 'vuex';

export default {
  components: {
    CollapsibleDrawer,
    CreateEditPrizeModal,
    DateTimeDisplay,
    DeletePrizeModal,
    EarlyBirdIcon,
    PickWinnerModal,
    PrizeDrawerContent,
    PrizeWinnerBadge,
    ProgressBar,
    DownloadFile,
    Badge,
    BaseButton
  },
  props: {
    prize: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      drawerOpen: false,
      loadingWinners: true,
      buttonLoading: false,
      isSuperAdmin: false
    };
  },
  async mounted() {
    this.loadingWinners = false;
    this.isSuperAdmin = await unleashFeatureEnabled(UnleashKeys.SupportSuperAdmin);
  },
  computed: {
    prizeDrawDate() {
      return this.prize.drawDate ? this.prize.drawDate : this.event.endDate;
    },
    iconDisplay() {
      // Test if prize is early bird
      return this.isEarlyBird(this.prize.id, this.event.endDate);
    },
    winner() {
      const prizeWinners = this.getPrizeWinners(this.prize.id);

      const winner = prizeWinners.find((winner) => winner.status === 'confirmed');

      if (winner) {
        return {
          name: winner.order.name,
          ticketNumber: winner.ticket.fullTicketNumber,
          id: winner.id
        };
      }

      return false;
    },
    hasUnpickedWinner() {
      const prizeWinners = this.getPrizeWinners(this.prize.id);
      return !prizeWinners.some((winner) => winner.prizeId === this.prize.id);
    },
    showPickWinnerModal() {
      return (
        this.displayPickWinner(this.getPrizeWinners(this.prize.id), this.prize.drawDate) &&
        this.prize.picksCompleted !== this.prize.quantity &&
        this.prize.picksPending === 0 &&
        // If the event is closed, only super admins can pick winners
        ((this.event.status === 'closed' && this.isSuperAdmin) || this.event.status !== 'closed')
      );
    },
    showDownloadButton() {
      return this.prize.quantity > 1 && this.prize.picksCompleted === this.prize.quantity;
    },
    toolTipText() {
      return `${Math.floor((this.prize.picksCompleted / this.prize.quantity) * 100)}% (${this.prize.picksCompleted} / ${
        this.prize.quantity
      })`;
    },
    ...mapGetters([
      'displayPickWinner',
      'isEarlyBird',
      'getWinners',
      'getPrizeWinners',
      'getPrizeState',
      'showProgressBar'
    ])
  },
  methods: {
    edit() {
      this.$bvModal.show(`edit-prize-${this.prize.id}`);
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    async displayControls() {
      if (this.prizeState('pending') || !(await unleashFeatureEnabled(UnleashKeys.PrizeDrawsPrizeControls))) {
        return false;
      }
      return true;
    },

    async downloadBatchPickWinnerReport() {
      this.buttonLoading = true;
      try {
        const response = await WinnerServiceV2.listWinners(this.event.id, { prizeId: this.prize.id }, 'text/csv');
        return response;
      } catch (error) {
        this.buttonLoading = false;
        throw error;
      } finally {
        this.buttonLoading = false;
      }
    },

    exportError(error) {
      this.$emit('dowloadError', error);
    },

    complete(winner) {
      this.$emit('complete', winner);
    }
  }
};
</script>
