<template>
  <GenericModal
    ref="exportModal"
    :title="'Export Job Created'"
    :config="modalConfig"
    @modal-opened="onModalOpened"
    @modal-closed="onModalClosed"
  >
    <template #openButton="{ openModal }">
      <b-button variant="outline-secondary" class="ml-2" target="_top" @click="downloadReport(openModal)">
        <i class="fa-solid fa-download mr-2"></i>Purchased Tickets Report
      </b-button>
    </template>
    <template #default>
      <Alert v-if="errorMessage" variant="red" icon="exclamation">
        <p>{{ errorMessage }}</p>
      </Alert>
      <Alert v-else variant="green" icon="info">
        <p>
          Your export job has been created successfully. You can monitor the progress and download the file from the
          <a href="/exports" class="text-blue-500">Exports page</a> once it is ready.
        </p>
      </Alert>
    </template>
  </GenericModal>
</template>

<script>
import JobExportService from '@/lib/export-job-service-v2';
import GenericModal from '@/components/modals/GenericModal.vue';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    GenericModal,
    Alert
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      organizationId: null,
      csvData: null,
      canViewExports: false,
      errorMessage: null,
      modalConfig: {
        modalButtonLabel: 'Purchased Tickets Report',
        cancelButtonLabel: 'Close'
      }
    };
  },
  methods: {
    async downloadReport(openModal) {
      try {
        await JobExportService.createExportJob({
          organizationId: this.event.organizationId,
          reportName: 'purchased-tickets-report',
          filters: [{ field: 'eventId', operator: '=', value: this.event.id }]
        });
        openModal();
      } catch (error) {
        this.errorMessage = this.formatErrorMessage(error);
        openModal();
      }
    },
    formatErrorMessage(error) {
      if (error.response && error.response.data && error.response.data.errors) {
        return error.response.data.errors.map((err) => `${err.message}: ${err.path}`).join(', ');
      } else {
        return 'An unexpected error occurred.';
      }
    }
  }
};
</script>
